
import * as React from "react";

function HRfooter() {
    
    return (
        <>
                 <div className="footermain">
            <div className="HRfooter">
              <span>© 2024 – </span>
            <a style={{paddingLeft:'4px'}}
              href="https://www.apps365.com" target="_blank" rel="noreferrer">
                Apps 365.
              </a>
              <a
                href="https://apps365.com/wp-content/uploads/Apps365-SLA.pdf"
                target="_blank"
                rel="noreferrer"
              >
                SLA.
              </a>
              <a
                href="https://apps365.com/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                T&C.
              </a>
              <a
                href="https://apps365.com/end-user-license-agreement-eula/"
                target="_blank"
                rel="noreferrer"
              >
                EULA.
              </a>
              <a
                href="https://apps365.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
            </a>
            <a href="https://www.cubiclogics.com" target="_blank" rel="noreferrer">
                Cubic Logics.
              </a>
              {/* <a href="https://www.hr365.us" target="_blank" rel="noreferrer">
                HR365
              </a> */}
            </div>
            <div className="HRfooter">
              {" "}
              {/* <span style={{ paddingLeft: "25px" }}>Cubic Logics Inc.</span> */}
              <span style={{ paddingLeft: "5px" }}>
                {" "}
                Cubic Logics Inc. 325, Front St W, #300, Toronto, ON - M5V 2Y1
              </span>
            </div>
            {/* <div className="HRfooter mbHRfooter">
              <a
                href="https://www.instagram.com/hr365.us/"
                target="_blank"
                rel="noreferrer"
                className="svgstyle"
              >
                <AiOutlineInstagram />
              </a>
              <a
                href="https://www.facebook.com/cubiclogics/"
                target="_blank"
                rel="noreferrer"
                className="svgstyle"
              >
                <AiOutlineFacebook />
              </a>
              <a
                href="https://twitter.com/HR365Apps"
                target="_blank"
                rel="noreferrer"
                className="svgstyle"
              >
                <AiOutlineTwitter />
              </a>
              <a
                href="https://www.linkedin.com/company/cubiclogics"
                rel="noreferrer"
                target="_blank"
                className="svgstyle"
              >
                <AiOutlineLinkedin />
              </a>
              <a
                href="https://youtube.com/@hr365?si=HFuJtDl0QmKWRj2D"
                rel="noreferrer"
                target="_blank"
                className="svgstyle"
              >
                <AiOutlineYoutube />
              </a>
            </div> */}
          </div>
        
        </>
    )
}
export default HRfooter;