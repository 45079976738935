import * as React from "react";
import DemoButtons from "./DemoButtons";
import { createBrowserRouter, NavLink, RouterProvider } from "react-router-dom";
import { Label } from "@fluentui/react";
import { Link } from "react-router-dom";
import { ImGift } from "react-icons/im";
import { FaEarDeaf } from "react-icons/fa6";
import { RiTeamFill } from "react-icons/ri";
import { LuEar } from "react-icons/lu";
import Lightbox from "./Lightbox";
import { GrGrow } from "react-icons/gr";
import Civicaboutus from "./Civicaboutus";
import { FaRegHandshake } from "react-icons/fa6";
import { BsRobot } from "react-icons/bs";
import { AiOutlineCustomerService } from "react-icons/ai";
import { RiRobot2Line } from "react-icons/ri";
import { SiStylelint } from "react-icons/si";
import { PiHandshakeLight } from "react-icons/pi";
import { IoColorPaletteOutline } from "react-icons/io5";
import HRfooter from "./HRFooter";
function ActualAboutUs(props) {
  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");
  React.useEffect(() => {
    let currentMode = false;
    window
      .matchMedia("(prefers-color-scheme: dark)")
      ?.addEventListener("change", (event) => {
        currentMode = event?.matches ? true : false;
      });
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute(
      "href",
      currentMode
        ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/Civic365%2096px%20Trans%20White_pMnITvnhR.png?updatedAt=1707741529554"
        : "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/Civic365%2096px%20Trans_myLSK6DmS.png?updatedAt=1707741733280"
    );
  }, []);
  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="HR_MainHeaderSection HR_panel">
            <div className="HR_FlexCenterSpcBetween">
              <div className="HR_LogoSection">
                <a
                  href="https://www.cubiclogics.com"
                  target="_blank"
                  rel="noreferrer"
                  className="HR_logoaboutus"
                >
                  <img
                    width={'15vw'}
                    src={
                      "https://www.cubiclogics.com/wp-content/uploads/2023/11/1276Purple-Cubic-Trans-2024-copy.png"
                      // "https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/civic_shortColored%20(1)_eyKLS1cDu.png?updatedAt=1706079031529"
                    }
                    alt="Civic365"
                  />
                  {/* <Label>Civic 365</Label> */}
                </a>
              </div>
              <div className="HR_FlexCenterSpcBetween" style={{ width: "20%" }}>
                <div className="MB_button">
                  <div className="HR_DemoButtonsSection headerbutton">
                    <div
                      style={{
                        width: "8vw",
                      }}
                      className="HR_PrimaryButton"
                    >
                      <Link to={props.page == "civic365" ? "/civic365" : "/helpdesk"}>
                        <button>Home</button>
                      </Link>
                    </div>
                    <div className="HR_DemoButtons">
                      <div
                        style={{
                          width: "10vw",
                        }}
                        className="HR_PrimaryButton"
                      >
                        <a
                          href="https://request.civic365.ca"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button>Citizen's Login</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">Cubic Logics</h1>
            <p className="HR_textclr">
              Founded in 2015 by visionary industry professionals formerly from
              HP, IBM, and Microsoft, Cubic Logics began with a singular vision:
              to bridge the gap between enterprise-grade applications and the
              needs of small to medium-sized businesses. Recognizing the
              challenges these businesses faced, our founders envisioned
              solutions that were not only cost-effective but also simple to
              acquire, deploy, and learn.
              <br />
              Today, Cubic Logics stands as a beacon of digital transformation,
              having garnered the trust of Fortune 500 clients such as Siemens,
              KPMG, and Cricket Australia. Our footprint extends across the
              globe, with associations ranging from U.S. federal government to
              the Australian government and numerous state and city government
              agencies in the U.S., Canada, UK, and the Singapore.
            </p>

            <div className="HR_OuterDemoButtons">
              {/* <div className="desktopView">
                <DemoButtons />
              </div> */}
              <div className="HR_DemoButtons MobileView">
                <div className="HR_PrimaryButton">
                  <a
                    href="https://request.civic365.ca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>Citizen's Login</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://www.cubiclogics.com/wp-content/uploads/2023/11/%E2%80%94Pngtree%E2%80%94people-working-in-a-table_7537097-1.png"
              }
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="HR_whitesection">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/random-images/Our%20Vision%20Aboout%20US_-UuaMS-IRS.png?updatedAt=1700646146427"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/random-images/Our%20Vision%20Aboout%20US_-UuaMS-IRS.png?updatedAt=1700646146427"
                )
              }
            />
            <Lightbox
              isOpen={isLightboxOpen}
              onClose={closeLightbox}
              imageUrl={selectedImageUrl}
            />
          </div>
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">Our Vision</h2>
            <p className="HR_textclr">
              Prioritizing cybersecurity and privacy, our dream is to shine on
              the global stage by offering outstanding Business and HR solution,
              and aiding businesses in their digital transformation. We always
              aspire to lead the way, setting the highest standards of
              innovation and excellence.
            </p>
          </div>
        </div>
      </div>
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">Our Mission</h2>
            <p className="HR_textclr">
              Civic 365 offers a revolutionary approach to managing citizen
              service requests. Our platform, anchored by our robust 311 CRM
              system, delivers streamlined, efficient, and user-friendly online
              solutions, fully integrated with key local government
              applications. Powered by Microsoft 365 and Azure, Civic365 makes
              tracking, reporting, and optimizing citizen issues effortless.
            </p>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/random-images/Our%20Mission%20Aboout%20US__rVh2eAya.png?updatedAt=1700639422480"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/random-images/Our%20Mission%20Aboout%20US__rVh2eAya.png?updatedAt=1700639422480"
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="FeatureSection">
        <h4 className="FeatureHeading">Why Choose Cubic Logics?</h4>
        <div className="MainFeaturebox">
          <div className="Featurebox">
            <div className="FeatureImage">
              <BsRobot/>
            </div>
            <div className="FeatureContent">
              <Label>AI-Powered Solutions</Label>
              <p>
                At Cubic, we harness the power to AI develop automotive apps
                that not only simplify complex tasks but also provide actionable
                insights. These AI-driven solutions empower businesses to excel,
                innovate, and stay ahead of the competition.
              </p>
            </div>
          </div>
          <div className="Featurebox">
            <div className="FeatureImage">
              <SiStylelint/>
            </div>
            <div className="FeatureContent">
              <Label>Modern UI/UX</Label>
              <p>
                At Cubic, we deeply understand the importance of user-centric
                design which gels well with Microsoft apps to have fluid flow
                while working within Microsoft 365. We prioritize designing our
                products with a modern, intuitive interface, ensuring that every
                interaction is seamless and efficient for the user.
              </p>
            </div>
          </div>
          <div className="Featurebox">
            <div className="FeatureImage">
            <FaRegHandshake/>
            </div>
            <div className="FeatureContent">
              <Label>Commitment to Excellence</Label>
              <p>
                Our team at Cubic is a blend of seasoned experts and dynamic
                innovators. We ensure every product we release is of the rich in
                features with high quality, and performs at its best. We take
                pride in setting industry standards and continually raising the
                bar of excellence. ​
              </p>
            </div>
          </div>
          <div className="Featurebox">
            <div className="FeatureImage">
              <AiOutlineCustomerService/>
            </div>
            <div className="FeatureContent">
              <Label>Dedicated to Our Clients​</Label>
              <p>
                At the heart of Cubic's ethos is our clients. We value and
                respect their unique goals and visions. Through collaborative
                efforts, deep understanding, and tailored strategies, we craft
                solutions that not only meet the immediate needs of our clients
                but also position them for future success.​
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="WorkSection">
        <h4 className="WorkHeading">Why Work With us?</h4>
        <div className="MainWorkbox">
          <div className="Workbox">
            <div className="WorkImage">
              <RiTeamFill/>
            </div>
            <div className="WorkContent">
              <Label>Team Fun</Label>
              <p>
                At Cubic Logics, camaraderie is our mantra. Our teams not only
                work in harmony but also share moments of joy, laughter, and
                learning. Whether it's brainstorming sessions, team outings, or
                just a casual coffee break, we ensure that our work environment
                is filled with positive vibes and fun-filled memories.
              </p>
            </div>
                  </div>
                  <div className="Workbox">
            <div className="WorkImage">
              <GrGrow/>
            </div>
            <div className="WorkContent">
              <Label>Learn a Lot</Label>
              <p>
              Personal growth is pivotal to us. We offer a plethora of opportunities for continuous learning. Through workshops, training sessions, and hands-on projects, we ensure that our team members are equipped with the latest skills and knowledge to stay at the forefront of the industry.
              </p>
            </div>
                  </div>
                  <div className="Workbox">
            <div className="WorkImage">
             <IoColorPaletteOutline/>
            </div>
            <div className="WorkContent">
              <Label>Make Cool Stuff</Label>
              <p>
              Innovation is in our DNA. By joining us, you'll have the chance to be at the heart of developing cutting-edge computer tools and applications. Our projects are diverse, challenging, and impactful – giving you a platform to showcase your creativity and technical prowess.
              </p>
            </div>
          </div>
              </div>
              <div className="SubWorkbox">
          <div className="Workbox">
            <div className="WorkImage">
            <ImGift />
            </div>
            <div className="WorkContent">
              <Label>Get Rewarded</Label>
              <p>
              At Cubic Logics, we believe in rewarding dedication and excellence. Our compensation packages are competitive, and we offer a range of benefits that cater to the well-being of our team members. From health benefits to flexible work schedules, we ensure our team feels valued and taken care of.
              </p>
            </div>
                  </div>
                  <div className="Workbox">
            <div className="WorkImage">
            <LuEar/>
            </div>
            <div className="WorkContent">
              <Label>Be Recognized</Label>
              <p>
              We celebrate every achievement, big or small. If you bring something exceptional to the table, we ensure that your efforts are highlighted and applauded. Recognition at Cubic Logics isn't just about accolades; it's about fostering a culture where every team member's contribution is acknowledged and appreciated.
              </p>
            </div>
                  </div>
               
        </div>
      </div>

      <div className="HR_whitesection">
        <h1 className="HR_primaryclr">
          Ready to Elevate Your Citizen Experience?
        </h1>
        <p className="HR_textclr">
          Explore our government technology solutions today and redefine how
          your community interacts with local government services.
        </p>
        <div className="HR_OuterDemoButtons HR_MainButton">
          <DemoButtons />
        </div>
      </div>
<HRfooter/>
    </>
  );
}
export default ActualAboutUs;
